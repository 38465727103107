import {
  footerComplianceNavigationQuery,
  footerNavigationQuery,
  globalHeaderBannerQuery,
  mainNavigationQuery
} from 'graphql/queries/global';
import { LinksCMSData, NavLinkCMSData } from 'types/cms/fragments';
import { GlobalHeaderBannerQueryData, MainNavigationQueryItemCollection } from 'types/cms/queries';
import { UnknownObjectAny } from 'types/global.types';
import { CONTENT_TYPES } from 'utils/constants';
import { isD365FormValid } from 'utils/contentfulUtils';
import { graphQLErrorHandler } from 'utils/graphQLHandlers';

const spaceId = process.env.CONTENTFUL_SPACE_ID;
const apiKey = process.env.CONTENTFUL_API_KEY;
const previewApiKey = process.env.CONTENTFUL_PREVIEW_API_KEY;

const fetchContentfulData = async (
  data: {
    query: string;
    variables?: UnknownObjectAny;
  },
  preview = false
): Promise<UnknownObjectAny | unknown> => {
  try {
    const response = await fetch(`https://graphql.contentful.com/content/v1/spaces/${spaceId}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${preview ? previewApiKey : apiKey}`,
        'Cache-Control': 'no-cache'
      },
      body: JSON.stringify({
        // Trimming whitespace and removing newline characters to help keep query size from getting too large
        query: data.query.replace(/\r?\n|\r/g, '').trim(),
        variables: data?.variables
      })
    });

    const json = await response.json();

    // Used as a safety rail for when null values exist in body collection.
    if (json?.data?.pageCollection?.items?.[0]?.bodyCollection?.items) {
      json.data.pageCollection.items[0].bodyCollection.items =
        json.data.pageCollection.items[0].bodyCollection.items?.filter((item: any) => !!item);
      const d365FormIndex = json.data.pageCollection.items[0].bodyCollection.items.findIndex(
        (item: any) => item?.__typename == CONTENT_TYPES.D365_FORM
      );
      if (d365FormIndex !== -1) {
        const isFormValid = await isD365FormValid(json.data.pageCollection.items[0].bodyCollection.items[d365FormIndex])
        json.data.pageCollection.items[0].bodyCollection.items[d365FormIndex].isFormValid = isFormValid;
      }
    }
    return json;
  } catch (error) {
    console.error('ERROR FETCHING CONTENTFUL DATA FROM GRAPHQL API');
    console.error(error);
    return error;
  }
};

export interface GlobalAppData {
  globalHeaderBanner: GlobalHeaderBannerQueryData;
  mainNavigation: MainNavigationQueryItemCollection;
  footerNavigation: { footerNav: NavLinkCMSData[]; complianceNav: LinksCMSData[] };
}

export const fetchGlobalAppData = async (): Promise<GlobalAppData> => {
  const bannerResponse = (await fetchContentfulData({
    query: globalHeaderBannerQuery
  })) as UnknownObjectAny;
  const navigationResponse = (await fetchContentfulData({
    query: mainNavigationQuery
  })) as UnknownObjectAny;
  const footerNavigationResponse = (await fetchContentfulData({
    query: footerNavigationQuery
  })) as UnknownObjectAny;
  const footerComplianceNavigationResponse = (await fetchContentfulData({
    query: footerComplianceNavigationQuery
  })) as UnknownObjectAny;

  graphQLErrorHandler('bannerResponse', bannerResponse?.errors);
  graphQLErrorHandler('navigationResponse', navigationResponse?.errors);
  graphQLErrorHandler('footerNavigationResponse', footerNavigationResponse?.errors);
  graphQLErrorHandler(
    'footerComplianceNavigationResponse',
    footerComplianceNavigationResponse?.errors
  );

  const footerNavigation = {
    footerNav: footerNavigationResponse?.data?.list?.itemCollection?.items?.filter(
      (navList: UnknownObjectAny) => {
        return navList?.title !== footerComplianceNavigationResponse?.data?.navCategoryItem?.title;
      }
    ),
    complianceNav:
      footerComplianceNavigationResponse?.data?.navCategoryItem?.subcategoriesCollection?.items
  };

  return {
    footerNavigation,
    globalHeaderBanner: bannerResponse?.data?.globalHeaderBanner,
    mainNavigation: navigationResponse?.data?.list?.itemCollection?.items
  };
};

export default fetchContentfulData;
