import React, { ComponentProps, ReactElement } from 'react';
import { Box, ListItem, UnorderedList } from '@chakra-ui/layout';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import LDLink, { LDLinkProps } from './LDLink';
import { NavLinkCMSData } from 'types/cms/fragments';

interface LDNavAccordionProps {
  data: NavLinkCMSData[];
  AccordionProps?: Omit<ComponentProps<typeof Accordion>, 'allowToggle'>;
  AccordionItemProps?: ComponentProps<typeof AccordionItem>;
  AccordionButtonProps?: ComponentProps<typeof AccordionButton>;
  AccordionPanelProps?: ComponentProps<typeof AccordionPanel>;
  AccordionPanelUnorderedListProps?: ComponentProps<typeof UnorderedList>;
  AccordionPanelListItemProps?: Omit<ComponentProps<typeof ListItem>, 'key'>;
  TitleLDLinkProps?: Omit<LDLinkProps, 'linkText' | 'href'>;
  SubLDLinkProps?: Omit<LDLinkProps, 'linkText' | 'href'>;
}

const LDNavAccordion = ({ data, ...props }: LDNavAccordionProps): ReactElement => {
  return (
    <Accordion allowToggle {...props.AccordionProps}>
      {data?.map((linkSection) => (
        <AccordionItem
          {...props.AccordionItemProps}
          key={linkSection.title}
          id={props.AccordionButtonProps?.id ?? linkSection?.title}
        >
          {({ isExpanded }) => (
            <>
              {linkSection.route ? (
                <AccordionButton {...props.AccordionButtonProps}>
                  <Box flex="1" textAlign="left">
                    <LDLink
                      linkText={linkSection?.title}
                      href={linkSection.route}
                      color={props.TitleLDLinkProps?.color ?? 'primary.default'}
                      fontWeight={props.TitleLDLinkProps?.fontWeight ?? 700}
                      lineHeight={props.TitleLDLinkProps?.lineHeight ?? '1.5em'}
                      fontSize={props.TitleLDLinkProps?.fontSize ?? 'sm'}
                      textTransform={props.TitleLDLinkProps?.textTransform ?? 'capitalize'}
                      {...props.TitleLDLinkProps}
                    />
                  </Box>
                  {isExpanded ? (
                    <MinusIcon h="0.875em" w="0.875em" color="shades.300" />
                  ) : (
                    <AddIcon h="0.875em" w="0.875em" color="shades.300" />
                  )}
                </AccordionButton>
              ) : (
                <h2>
                  <AccordionButton
                    {...props.AccordionButtonProps}
                    textTransform={props.AccordionButtonProps?.textTransform ?? 'capitalize'}
                    color={props.AccordionButtonProps?.color ?? 'primary.default'}
                    fontWeight={props.AccordionButtonProps?.fontWeight ?? 700}
                    fontSize={props.AccordionButtonProps?.fontSize ?? 'md'}
                    padding={props.AccordionButtonProps?.padding ?? '3.5'}
                  >
                    <Box flex="1" textAlign="left" lineHeight="1.5em">
                      {linkSection?.title}
                    </Box>
                    {isExpanded ? (
                      <MinusIcon h="0.875em" w="0.875em" color="shades.300" />
                    ) : (
                      <AddIcon h="0.875em" w="0.875em" color="shades.300" />
                    )}
                  </AccordionButton>
                </h2>
              )}
              <AccordionPanel p={props.AccordionPanelProps?.p ?? 5} {...props.AccordionPanelProps}>
                <UnorderedList
                  w={props.AccordionPanelUnorderedListProps?.w ?? 'auto'}
                  listStyleType={props.AccordionPanelUnorderedListProps?.listStyleType ?? 'none'}
                  m={props.AccordionPanelUnorderedListProps?.m ?? '0 1em 0 0'}
                  pl={props.AccordionPanelUnorderedListProps?.m ?? '1.25em'}
                  {...props.AccordionPanelUnorderedListProps}

                >
                  {linkSection?.subcategoriesCollection?.items?.map((link) => (
                    <ListItem
                      textTransform={props.AccordionProps?.textTransform ?? 'capitalize'}
                      lineHeight={props.AccordionProps?.lineHeight ?? '2.5'}
                      key={link?.title}
                      {...props.AccordionPanelListItemProps}
                      _hover={{ bg: 'shades.100' }}
                    >
                      <LDLink
                        linkText={link?.title}
                        href={link?.route}
                        color={link?.route ?? props.SubLDLinkProps?.color ?? 'default'}
                        lineHeight={props.SubLDLinkProps?.lineHeight ?? '2.714285em'}
                        fontSize={props.SubLDLinkProps?.fontSize ?? 'sm'}
                        {...props.SubLDLinkProps}
                      />
                    </ListItem>
                  ))}
                </UnorderedList>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default LDNavAccordion;
