import { Box, Flex, ListItem, Text, UnorderedList, Container } from '@chakra-ui/layout';
import React, { ComponentProps, ReactElement } from 'react';
import { observer } from 'mobx-react';

import LDLink from './common/LDLink';
import LDNavAccordion from './common/LDNavAccordion';
import { commonGradients } from 'theme';
import { NavLinkCMSData, LinksCMSData } from 'types/cms/fragments';
import { useStore } from 'store';
import { Image } from '@chakra-ui/react';

const Footer = ({ simpleFooter }: { simpleFooter: boolean }): ReactElement => {
  const { cmsStore } = useStore();

  const footerNavigation: NavLinkCMSData[] = cmsStore?.footerNavigation?.footerNav;
  const footerComplianceNavigation: LinksCMSData[] = cmsStore?.footerNavigation?.complianceNav;

  // Shared footer components start
  const SeparationBar = () => (
    <>
      <Box bgColor="extras.olive" h="0.75em" w="100%" mb="0.25em" />
      <Box bgColor="primary.default" h={{ base: '2.75rem', md: '3.5em' }} w="100%" mb="0.25em" />
    </>
  );

  const FooterLogo = () => (
    <Flex
      w={{ base: '100%', lg: '18%' }}
      justifyContent="center"
      h={{ lg: '100%' }}
      mb={{ base: '2em', lg: '0' }}
    >
      <Box
        mt={{ lg: '0.65em' }}
        mr={{ lg: '4em' }}
        w={{ base: '100px', sm: '130px', md: '157.29px' }}
        h={{ base: '41.77px', sm: '54.3px', md: '65.69px' }}
        position="relative"
      >
        <Image src="/LD-pharmacy-logo.png" layout="fill" alt="London Drugs logo" />
      </Box>
    </Flex>
  );

  const FooterComplianceLinks = () => {
    const commonLinkProps: Omit<ComponentProps<typeof LDLink>, 'href' | 'linkText'> = {
      target: '_blank',
      rel: 'noopener noreferrer',
      color: 'primary.default',
      whiteSpace: 'noWrap',
      fontWeight: 700,
      _hover: { color: 'primary.hover', textDecoration: 'underline' }
    };

    return (
      <Box w="100%" padding={{ base: '2em', lg: 0 }}>
        <Flex
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
          fontSize="0.8125rem"
          lineHeight="2"
          justifyContent={{ base: 'center', lg: 'flex-start' }}
          textAlign={{ base: 'center' }}
        >
          {footerComplianceNavigation?.map((linkData, index) => (
            <React.Fragment key={linkData?.title}>
              <LDLink
                linkText={linkData?.title}
                href={linkData?.route}
                color={linkData?.textColor ?? commonLinkProps?.color}
                {...commonLinkProps}
                mr={index === 1 ? { base: '1em', sm: '0' } : undefined}
                _hover={{ color: 'primary.darker' }}
              />
              {index === footerComplianceNavigation?.length - 1 ? null : (
                <Box
                  as="span"
                  mx="10px"
                  h="100%"
                  d={index === 1 ? { base: 'none', sm: 'block' } : undefined}
                >
                  |
                </Box>
              )}
            </React.Fragment>
          ))}
        </Flex>
        <Text fontSize="0.75em" lineHeight="2" textAlign={{ base: 'center', lg: 'left' }}>
          © {new Date().getFullYear()} London Drugs. All rights reserved.
        </Text>
      </Box>
    );
  };

  const SocialMediaLinks = () => {
    return (
      <Flex gridGap="3" alignItems="center" justifyContent="center" mb={{ base: 0, lg: 4 }}>
        <Image src="/assets/icons/facebook.svg" width={30} height={30} alt="Facebook" />
        <Image src="/assets/icons/twitter.svg" width={30} height={30} alt="Twitter" />
        <Image src="/assets/icons/instagram.svg" width={30} height={30} alt="Instagram" />
        <Image src="/assets/icons/pinterest.svg" width={30} height={30} alt="Pinterest" />
      </Flex>
    );
  };

  // Shared footer components end

  if (simpleFooter) {
    return (
      <Box w="100%">
        <SeparationBar />
        <Flex
          align="center"
          justify="center"
          paddingY="5"
          paddingX="10"
          direction={{ base: 'column', md: 'row' }}
        >
          <FooterLogo />
          <Text fontSize="1em" lineHeight="2" textAlign={{ base: 'center', lg: 'left' }}>
            © {new Date().getFullYear()} London Drugs. All rights reserved.
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <>
      {/* Mobile footer 768px and down */}
      <Box w="100%" display={{ base: 'block', lg: 'none' }}>
        <SeparationBar />
        <Box p="2em 0" bg={commonGradients?.whiteGrayToTransparent}>
          <FooterLogo />
          <LDNavAccordion data={footerNavigation} />
          <FooterComplianceLinks />
          <SocialMediaLinks />
        </Box>
      </Box>
      {/* Desktop footer 769px and up */}
      <Box w="100%" display={{ base: 'none', lg: 'block' }}>
        <SeparationBar />
        <Flex
          h="auto"
          justifyContent="center"
          py="2em"
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          flexDir={{ base: 'column', lg: 'row' }}
          bg={commonGradients?.whiteGrayToTransparent}
        >
          <Container maxW="container.xxl">
            <Flex>
              <Flex
                flex="1"
                alignItems="flex-start"
                h="auto"
                mb={{ base: '2em', lg: '0' }}
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box
                  w={{ base: '100px', sm: '130px', md: '157.29px' }}
                  h={{ base: '41.77px', sm: '54.3px', md: '65.69px' }}
                >
                  <Image
                    src="/LD-pharmacy-logo.png"
                    layout="responsive"
                    width={158}
                    height={66}
                    alt="London Drugs logo"
                  />
                </Box>
                <SocialMediaLinks />
              </Flex>
              <Box flex={{ md: '4', lg: '3' }} justifyContent="space-between">
                <Flex w="100%" height="auto" marginBottom="3em" justifyContent="space-between">
                  {footerNavigation?.map((linkSection) => (
                    <UnorderedList
                      w="auto"
                      listStyleType="none"
                      m="0 1em 0 0"
                      key={linkSection?.title}
                    >
                      <ListItem
                        fontWeight={700}
                        color="primary.default"
                        textTransform="capitalize"
                        lineHeight="2em"
                        fontSize="1.125em"
                        key={linkSection?.title}
                      >
                        {linkSection?.title}
                      </ListItem>
                      {linkSection?.subcategoriesCollection?.items?.map((link) => (
                        <ListItem textTransform="capitalize" key={link?.title}>
                          <LDLink
                            linkText={link?.title}
                            href={link?.route}
                            color={link?.textColor ?? 'default'}
                            lineHeight="2em"
                            fontSize="0.875em"
                            _hover={{ color: 'primary.default' }}
                          />
                        </ListItem>
                      ))}
                    </UnorderedList>
                  ))}
                </Flex>
                <FooterComplianceLinks />
              </Box>
            </Flex>
          </Container>
        </Flex>
      </Box>
    </>
  );
};

export default observer(Footer);
